import React                    from 'react';
import ContactUsButtonComponent from "../components/authAndNav/contact-us-button.component";
import {Footer}                 from "../components/authAndNav/footer.component";

const aboutPerformance = require('./../assets/about.png');
const aboutInflation = require('./../assets/inflation.png');


const About = () => {

	return (
		<div className="aboutPageWrapper">
			<h1 className="">About PersonalityMap</h1>
			<div>
				<p>PersonalityMap is a platform to help you understand the relationship between "everything and
					everything" with regard to human psychology. We provide access to over 1 million correlations
					spanning personality, demographics, behaviors, and beliefs. In addition, we provide a powerful and
					one-of-a-kind interface for exploring these relationships, allowing you to investigate questions of
					interest from a variety of angles. </p>
				<p>
					PersonalityMap can be used to generate new hypotheses, as well as to refine and confirm (or
					disconfirm) existing hypotheses. You can use PersonalityMap's
					tools to investigate an extremely diverse array of questions, such as:</p>
				<ul className='lead'>
					<li>Are conservatives happier than progressives?</li>
					<li>Is anxiety linked to irritability?</li>
					<li>Do women tend to be more spiritual than men?</li>
					<li>Do extraverts see themselves as being more charismatic than other people do?</li>
					<li>What traits are most predictive of school grade point average?</li>
					<li>What are the predictors of being a "nerd"?</li>
					<li>How do people who believe in the paranormal differ from those that don't?</li>
					<li>What are the factors related to religious belief?</li>
					<li>What are the predictors of addiction?</li>
					<li>How do those who are heavy smokers differ from those who don't?</li>
					<li>How do more conformist people differ from those who break social rules?</li>
					<li>What are the predictors of people enjoying being the center of attention?</li>
					<li>Do depressed people worry about the future more than other people do?</li>
					<li>What is the relationship between procrastination and the Big Five personality trait of
						conscientiousness?
					</li>
				</ul>
				<p>
					To learn more about how PersonalityMap can be used in different ways, such as to help you better
					understand traits, check claims, generate hypotheses, test hypotheses, and rule out some causal
					pathways, see <a
					href="https://www.clearerthinking.org/post/personalitymap-a-digital-petri-dish-to-study-human-psychology"
					target="_blank">this article</a>.</p>
				<p>PersonalityMap is powered by an advanced, novel machine-learning algorithm of our own design. While
					its predictions
					are not always accurate, in a study testing its accuracy, it outperformed 100% of non-experts and
					99% of academic
					psychologists in predicting correlations:</p>
				<p><img src={aboutPerformance} alt="PersonalityMap Performance"
				        className="img-fluid p-3"/></p>
				<p>To learn more, see <a target="_blank" href="https://www.nature.com/articles/s44271-025-00205-w">AI
					can outperform humans in predicting correlations between personality items</a>.</p>
				<p>Currently all PersonalityMap predictions are made for the U.S., but in the future we plan to expand
					to other countries.</p>


			</div>
			<div>
				<h2>How to Interpret PersonalityMap Results</h2>
				<p>PersonalityMap shows two kinds of correlations: those coming from studies (which we call "Study
					Correlations") and those that are predicted correlations coming from our Machine Learning model
					(which we call "Predicted Correlations"). For the most accurate result, always use Study
					Correlations. However, since studies only existed for a limited number of questions, you can use the
					Predicted Correlations to get an estimate of what the correlation might be if a study were to be
					conducted.</p>
				<p>When it comes to Predicted Correlations, they are typically most accurate when you are searching for
					exact statements already found in our database (i.e., those that are automatically suggested to you
					as you type). You can also use PersonalityMap to produce predictions for statements that are novel
					(e.g., ones you make up) but those predictions will be less accurate. The less similar those novel
					statements are to any in the studies we've trained our Machine Learning model on, the less accurate
					the predictions will tend to be.</p>
				<p>Items and questions in our database are always treated in such a way that higher numbers mean greater
					agreement (or more of that thing). So, if PersonalityMap predicts that the correlation
					between <span
						className='lead'>"I'm someone who has a hard time relaxing" </span> and <span
						className='lead'>"It is typical for me to feel nervous, anxious, or on edge"</span> is <span
						className='resultHighlight'>r = 0.68</span>, then that means it is predicting that people's
					level
					of agreement with the first statement
					is strongly correlated to their level of agreement with the second statement.</p>
				<p>Correlations range from <span className='resultHighlight'>-1</span> (when to variables move perfectly
					in lock step in opposite directions) to
					<span className='resultHighlight'>+1</span> (when they move perfectly in lock step in the same
					direction). As rules of thumb, a correlation
					of <span className='resultHighlight'>r = 0.1</span> is often considered small, <span
						className='resultHighlight'>0.3</span> considered medium,
					and <span className='resultHighlight'>0.5</span> considered large. However, in
					practice context matters. Whether a correlation is meaningfully large depends on what variables the
					correlation actually involves, and what it is being used for. For example, a correlation of <span
						className='resultHighlight'>r = 0.2</span> could be considered meaningfully large if life or
					death or large sums of money are on the line, but considered small in a lower stakes setting when
					better predictors (with higher correlations) already are known.</p>
				<p>Additionally, keep in mind that correlations often do not imply causality. So, be careful to not
					assume that A causes B just because A and B are correlated. If A and B are shown to be correlated
					(either in a study or based on predictions), any of the following could be the explanation:</p>
				<ul>
					<li>A causes B</li>
					<li>B causes A</li>
					<li>A and B both cause each other (circular causation)</li>
					<li>There exists some C that independently causes both A and B, but otherwise, A and B are
						unrelated
					</li>
					<li>A random fluke chance (if it is from a study) or a bad prediction (if it is a predicted
						correlation)
					</li>
					<li>Some combination of the above.</li>
				</ul>
				<p>
					Therefore, it's important to interpret correlations with caution and not jump to causal conclusions.
					To learn more about correlations, and how to interpret them, <a
					href="https://www.clearerthinking.org/post/what-is-a-correlation-and-how-do-you-think-clearly-about-it#:~:text=Almost%20always%2C%20correlations%20are%20not,'stronger'%20the%20relationship%20is."
					target='_blank'>see our article here</a>.
				</p>
				<section>
					<h3>Inflation caused by regression to the mean</h3>
					<p>
						Since PersonalityMap helps you find the statements that are predicted to most correlate with a
						given statement, there is a risk of <a
						href="https://en.wikipedia.org/wiki/Regression_toward_the_mean" target='_blank'>regression to
						the mean</a> effects. To understand this issue, consider that every correlation from studies
						that we report is a combination of two things:
						<br/>
						<span
							className='p-2 resultHighlight'>study correlation = true population correlation + error</span>
					</p>
					<p>One reason for error is due to sampling. Each study measures a subset of people in the
						population. The larger the number of study participants in the study, the smaller this error
						will be. However, error can also come from other sources, such as biases in the population being
						studied. Sometimes, the error will make a correlation look bigger than it is, whereas other
						times, it will make a correlation look smaller than it is. When the error happens, by chance, to
						increase (rather than decrease) the magnitude of a correlation, it is more likely to appear at
						the top of search results. Hence, we should expect that, if search results were sorted by study
						correlations, the ones at the top of the list would be upwardly biased (with the true
						correlations smaller in magnitude, on average, than those reported). The amount of this
						"inflation" of correlations depends a great deal on the sample size of the studies. This chart
						shows a simulation of this relationship (in a scenario where all true correlations are zero, so
						all apparent correlations are due to sampling error, and there are 5000 correlations being
						searched for a single search query):</p>
					<p className="text-center">
						<img src={aboutInflation} alt="Inflation caused by regression to the mean"
						     className="img-fluid p-3 aboutInflation"/></p>
					<p>We can see that for small sample sizes there is a lot of inflation of correlation magnitudes
						(e.g., <span className="resultHighlight"> 0.40 for n=100</span>), whereas for large sample
						sizes, inflation is very modest (e.g. about
						<span className="resultHighlight">0.12 for n=1000</span>, and about <span
							className="resultHighlight">0.05 for n=5000</span>). Analyzing the actual inflation produced
						by
						PersonalityMap is complex because we don't sort by study correlations, we sort by the magnitude
						of predicted correlations from our machine learning model, and so the inflation depends on the
						machine learning model itself. However, you can expect that there will be some inflation, on
						average, in the correlations shown at the top of search results. As we continue to improve the
						accuracy of our machine learning model (a major goal of ours) by improving its architecture and
						training it on more and more data, the inflation should become lower and lower.</p>
				</section>

			</div>
			<div>
				<h2></h2>
				<p>We use <span className='statementHighlight'>'PersonalityMap'</span> not 'Personality Map' to refer to
					the system.
				</p>
				<p>PersonalityMap is a creation of <a target="_blank" href="https://www.sparkwave.tech/">Spark Wave</a>.
				</p>
				<p>If you have questions about PersonalityMap or any feedback or suggestions for how we could improve
					the system, we'd
					love to hear from you. Contact us by clicking here:</p>
				<ContactUsButtonComponent/>
			</div>
			<Footer/>
		</div>
	);
};

export default About;